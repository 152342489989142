.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
  /* Color de texto */
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.alert {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  color: #721c24;
}

.btn-primary {
  width: 100%;
}

.login-page {
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilos CSS adicionales para destacar los contenedores de los lienzos de firma */
.contenedor-firma {
  margin: 20px; /* Margen alrededor del lienzo de firma */
  padding: 10px; /* Relleno interno del contenedor */
  border: 2px solid #333; /* Borde sólido de 2 píxeles con color gris oscuro */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
  background-color: #f0f0f0; /* Fondo gris claro */
}

/* Estilos adicionales para los títulos de los lienzos de firma */
.contenedor-firma h2 {
  margin-top: 0; /* Elimina el margen superior predeterminado de los títulos */
  font-size: 18px; /* Tamaño de fuente para los títulos */
  color: #333; /* Color de texto gris oscuro */
}

/* Estilos para los botones de subir firma */
button {
  margin-top: 10px; /* Margen superior para separar los botones */
}

.login-container {

  background-color: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.App-logo-extra-small {
  width: 200px;
  height: auto;
  margin: 10px;
}

.justify-content-center align-items-center {
  background-color: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

body {
  height: 100%;
  margin: 0;
}

.justify-content-center.align-items-center.row {
  width: 100%;
  height: 100%;
}